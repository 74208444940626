import React, { FC, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { Box, Typography, styled } from "@mui/material"

import { useLocalizationContext } from "@context"

const NotFoundPage: FC = () => {
  const navigate = useNavigate()
  const { l } = useLocalizationContext()

  const { notFound, back } = useMemo(() => l.ui, [l])

  return (
    <SBox>
      <Typography variant="h1" children={notFound} />
      <SLink variant="h3" onClick={(): void => navigate(-1)} children={back} />
    </SBox>
  )
}

const SBox = styled(Box)({
  width: "100%",
  height: "100vh",
  overflow: "hidden",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
})

const SLink = styled(Typography)({ cursor: "pointer" })

export default NotFoundPage
