import { Box, Button, FormGroup, styled, TextField } from "@mui/material"

const SContainer = styled(Box)({
  width: "100%",
  height: "calc(100vh - 100px)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
})

const SFormGroup = styled(FormGroup)({
  width: "408px",
})

const SButton = styled(Button)({
  width: "164px",
  height: "48px",
})

const STextField = styled(TextField)({
  input: {
    padding: "16.5px 14px",
  },
})

export { SContainer, SFormGroup, SButton, STextField }
