import { useReducer, useMemo } from "react"

import { ERequestStatus } from "@types"
import { isPaginatedList } from "../helpers"

interface IRestAPIState<T> {
  data: T
  status: ERequestStatus
}

export interface IRestAPIActions<T> {
  setData: (data: T) => void
  setPending: (isPending: boolean) => void
  setError: () => void
}

type TRestAPIAction<T> = {
  [ERequestStatus.IDLE]: { type: ERequestStatus.IDLE }
  [ERequestStatus.READY]: { type: ERequestStatus.READY; payload: T }
  [ERequestStatus.PENDING]: { type: ERequestStatus.PENDING }
  [ERequestStatus.EMPTY]: { type: ERequestStatus.EMPTY; payload: T }
  [ERequestStatus.ERROR]: { type: ERequestStatus.ERROR }
}

export function useRestAPIReducer<T>(initialData: T): [state: IRestAPIState<T>, restAPIActions: IRestAPIActions<T>] {
  const initialState = useMemo<IRestAPIState<T>>(
    () => ({
      data: initialData,
      status: ERequestStatus.IDLE,
    }),
    []
  )

  const reducer = (state: IRestAPIState<T>, action: TRestAPIAction<T>[ERequestStatus]): IRestAPIState<T> => {
    switch (action.type) {
      case ERequestStatus.PENDING:
        return {
          ...state,
          status: ERequestStatus.PENDING,
        }
      case ERequestStatus.READY:
        return {
          ...state,
          data: action.payload,
          status: ERequestStatus.READY,
        }
      case ERequestStatus.EMPTY:
        return {
          ...state,
          data: action.payload,
          status: ERequestStatus.EMPTY,
        }
      case ERequestStatus.ERROR:
        return {
          ...state,
          status: ERequestStatus.ERROR,
        }

      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const restAPIActions: IRestAPIActions<T> = useMemo(
    () => ({
      setData: (data: T): void => {
        let status = ERequestStatus.READY

        if ((Array.isArray(data) && !data.length) || (isPaginatedList(data) && !data.count))
          status = ERequestStatus.EMPTY

        dispatch({ type: status, payload: data })
      },
      setPending: (isPending): void => {
        if (isPending) dispatch({ type: ERequestStatus.PENDING })
      },
      setError: (): void => {
        dispatch({ type: ERequestStatus.ERROR })
      },
    }),
    []
  )

  return [state, restAPIActions]
}
