import { ILocale } from "@types"

class LocaleDTO {
  private _l: ILocale | null = null

  get l(): ILocale | null {
    return this._l
  }

  set l(l: ILocale) {
    this._l = l
  }
}

export const localeDTO = new LocaleDTO()
