// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ILObj = Record<string, any>

export enum ELang {
  RU = "RU",
  EN = "EN",
}

export interface ILocale {
  language: ELang
  ui: ILObj
  header: ILObj
  dashboard: ILObj
  marketplace: ILObj
}
