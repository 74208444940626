import { useCallback, useEffect } from "react"

import { industrialObjectAPI } from "@api"
import { ERequestStatus, IIndustrialObject } from "@types"
import { useRestAPIReducer } from "../_useRestAPIReducer"

interface IUseIndustrialObjectAPI {
  getIndustrialObjectList: () => void
  industrialObjectList: IIndustrialObject[]
  status: ERequestStatus
}

export const useIndustrialObjectAPI = (): IUseIndustrialObjectAPI => {
  const [{ data: industrialObjectList, status }, actions] = useRestAPIReducer<IIndustrialObject[]>([])

  const getIndustrialObjectList = useCallback(() => {
    industrialObjectAPI.getIndustrialObjectList({ actions })
  }, [])

  useEffect(() => {
    getIndustrialObjectList()
  }, [])

  return {
    getIndustrialObjectList,
    industrialObjectList,
    status,
  }
}
