import { Button, FormHelperText, styled, TextField } from "@mui/material"

const SButton = styled(Button)({
  width: "164px",
  height: "48px",
})

const STextField = styled(TextField)({
  input: {
    padding: "16.5px 14px",
  },
})

const SAuthError = styled(FormHelperText)({
  marginLeft: "0",
})

const SForm = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: "45px",
})

export { SButton, STextField, SForm, SAuthError }
