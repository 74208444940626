export class MiniStore<T> {
  private subscriptions: Set<() => void> = new Set<() => void>()
  private state: T
  constructor(initialState: T) {
    this.state = initialState
  }

  getState = (): T => {
    return this.state
  }

  update = (partialNewState: Partial<T>): void => {
    this.state = { ...this.state, ...partialNewState }

    this.subscriptions.forEach((cb) => {
      cb()
    })
  }

  subscribe = (cb: () => void): (() => void) => {
    this.subscriptions.add(cb)

    return () => {
      this.subscriptions.delete(cb)
    }
  }
}
