import React from "react"
import { Outlet } from "react-router-dom"
import { styled } from "@mui/material"

import { Header, ResponsiveWidthMessage } from "@components"

export const PageWrapper: React.FC = () => (
  <ResponsiveWidthMessage>
    <>
      <Header />
      <SWrapper children={<Outlet />} />
    </>
  </ResponsiveWidthMessage>
)

const SWrapper = styled("div")({
  maxWidth: "1920px",
  height: "calc(100% - 48px)",
  display: "flex",
  padding: "16px 40px",
  margin: "0 auto",
})
