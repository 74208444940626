import { Primitives } from "@types"

export class CookieJar {
  static getCookie(name: string): string | null {
    const matches = document.cookie.match(
      // eslint-disable-next-line
      new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)")
    )
    return matches ? decodeURIComponent(matches[1]) : null
  }

  static setCookie(name: string, value: string, options?: Record<string, Omit<Primitives, "symbol">>): void {
    options = {
      path: "/",
      domain: ".newscience.tech",
      ...options,
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value)

    for (const optionKey in options) {
      updatedCookie += "; " + optionKey
      const optionValue = options[optionKey]
      if (optionValue !== true) {
        updatedCookie += "=" + optionValue
      }
    }

    document.cookie = updatedCookie
  }

  static deleteCookie(name: string): void {
    this.setCookie(name, "", {
      "max-age": -1,
    })
  }
}
