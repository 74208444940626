/* eslint-disable @typescript-eslint/no-explicit-any */

import { EEmission, ELayer, IIndustrialObject, IPaginatedList, IStation } from "@types"

function isEEmission(value: string): value is EEmission {
  return Object.values<string>(EEmission).includes(value)
}

function isELayer(value: string): value is ELayer {
  return Object.values<string>(ELayer).includes(value)
}

function isIStation(data: any): data is IStation {
  if (data === null) return false

  return "address" in data
}

function isIIndustrialObject(data: any): data is IIndustrialObject {
  if (data === null) return false

  return "polygon" in data
}

function isPaginatedList(data: any): data is IPaginatedList {
  return data.count !== undefined && data.results !== undefined
}

export { isEEmission, isELayer, isIStation, isIIndustrialObject, isPaginatedList }
