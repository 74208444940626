import React, { useMemo, Suspense } from "react"
import { Route, Routes } from "react-router-dom"
import { CircularProgress, styled } from "@mui/material"

import { routes, ROUTE_PATHS } from "./_routes.constants"
import { PageWrapper, PrivateRoute } from "./fragments"
import { NotFoundPage, LoginPage } from "../pages"

export const RoutesComponent: React.FC = () => {
  const mappedRoutes = useMemo(() => {
    return routes.map(({ path, element: Page }) => (
      <Route
        path={path}
        element={
          <Suspense fallback={<SCircularProgress />}>
            <Page />
          </Suspense>
        }
        key={path}
      />
    ))
  }, [])

  return (
    <Routes>
      <Route path={ROUTE_PATHS.HOME} element={<LoginPage />} />

      <Route element={<PageWrapper />}>
        <Route element={<PrivateRoute />}>{mappedRoutes}</Route>
      </Route>

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

const SCircularProgress = styled(CircularProgress)({
  width: "60px !important",
  height: "60px !important",
  display: "block",
  alignSelf: "center",
  margin: "0 auto",
})
