import { useCallback } from "react"

import { stationGasAPI } from "@api"
import { ERequestStatus, IGetStationDataByAreaParams, IGetStationDataParams, IStationData } from "@types"
import { useRestAPIReducer } from "../../_useRestAPIReducer"

interface IUseStationGasAPI {
  getStationData: (params: IGetStationDataParams | IGetStationDataByAreaParams, isArea?: boolean) => void
  stationData: IStationData[]
  status: ERequestStatus
}

export const useStationGasAPI = (): IUseStationGasAPI => {
  const [{ data: stationData, status }, actions] = useRestAPIReducer<IStationData[]>([])

  const getStationData = useCallback((params: IGetStationDataParams | IGetStationDataByAreaParams, isArea = false) => {
    isArea
      ? stationGasAPI.getStationDataByArea({ payload: params }, actions)
      : stationGasAPI.getStationData({ params }, actions)
  }, [])

  return {
    getStationData,
    stationData,
    status,
  }
}
