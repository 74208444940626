import React, { ChangeEvent, FC, FormEvent, useCallback, useState } from "react"
import { Stack } from "@mui/material"
import { useNavigate } from "react-router-dom"

import { ROUTE_PATHS } from "@routes"
import { useLocalizationContext } from "@context"
import { CookieJar, useLatest } from "@utils"
import { SButton, STextField, SForm, SAuthError } from "./_styles"

const ACCESS_DATA = {
  login: "demo",
  password: "demo",
}

export const Form: FC = () => {
  const navigate = useNavigate()
  const {
    l: { ui },
  } = useLocalizationContext()

  const [login, setLogin] = useState("")
  const [password, setPassword] = useState("")
  const [isAuthError, setIsAuthError] = useState(false)

  const loginFieldsRef = useLatest({ login, password })

  const onChangeLogin = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setLogin(event.target.value)
  }, [])

  const onChangePassword = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }, [])

  const onSignIn = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const { login, password } = loginFieldsRef.current

    if (login !== ACCESS_DATA.login || password !== ACCESS_DATA.password) {
      setIsAuthError((prevState) => !prevState)
      setLogin("")
      setPassword("")
      return
    }

    CookieJar.setCookie("isAuthorized", "authorized")
    navigate(ROUTE_PATHS.DASHBOARD)
  }, [])

  return (
    <SForm onSubmit={onSignIn}>
      <Stack gap="20px">
        <STextField name="username" placeholder={ui.login} value={login} onChange={onChangeLogin} />
        <STextField
          name="password"
          placeholder={ui.password}
          value={password}
          onChange={onChangePassword}
          type="password"
        />
        {isAuthError && <SAuthError error={isAuthError} children={ui.wrongCredentials} />}
      </Stack>

      <SButton type="submit" variant="contained" children={ui.signIn} size="large" />
    </SForm>
  )
}
