import { FC, lazy, LazyExoticComponent } from "react"

export const ROUTE_PATHS = {
  HOME: "/",
  DASHBOARD: "/dashboard",
  MARKETPLACE: "/marketplace",
  MAP: "/map",
  FORECAST: "/forecast",
  ANALYTICS: "/analytics",
  SENSORS: "/sensors",
  JOURNAL: "/journal",
}

type TArrayRoutes = {
  path: string
  element: LazyExoticComponent<FC>
}[]

export const routes: TArrayRoutes = [
  {
    path: ROUTE_PATHS.DASHBOARD,
    element: lazy(() => import("../pages/dashboard_page")),
  },
  {
    path: ROUTE_PATHS.MARKETPLACE,
    element: lazy(() => import("../pages/marketplace_page")),
  },
  {
    path: ROUTE_PATHS.MAP,
    element: lazy(() => import("../pages/map_page")),
  },
  {
    path: ROUTE_PATHS.FORECAST,
    element: lazy(() => import("../pages/forecast_page")),
  },
  {
    path: ROUTE_PATHS.ANALYTICS,
    element: lazy(() => import("../pages/analytics_page")),
  },
  {
    path: ROUTE_PATHS.SENSORS,
    element: lazy(() => import("../pages/sensors_page")),
  },
  {
    path: ROUTE_PATHS.JOURNAL,
    element: lazy(() => import("../pages/journal_page")),
  },
]
