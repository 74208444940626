import React, { FC, useMemo } from "react"
import { Navigate } from "react-router-dom"
import { Stack } from "@mui/material"

import { BCLogo } from "@components"
import { ROUTE_PATHS } from "@routes"
import { CookieJar } from "@utils"
import { Form } from "./fragments"
import { SContainer, SFormGroup } from "./_styles"

const LoginPage: FC = () => {
  const isAuth = useMemo(() => CookieJar.getCookie("isAuthorized") === "authorized", [document.cookie])

  if (isAuth) return <Navigate to={ROUTE_PATHS.DASHBOARD} replace />

  return (
    <SContainer>
      <SFormGroup>
        <Stack gap="25px">
          <BCLogo width={136} height={60} />
          <Form />
        </Stack>
      </SFormGroup>
    </SContainer>
  )
}

export default LoginPage
