import { useCallback, useEffect } from "react"

import { stationAPI } from "@api"
import { ME_BOUNDS } from "@constants"
import { ERequestStatus, IBounds, IStationInfo } from "@types"
import { useRestAPIReducer } from "../../_useRestAPIReducer"

interface IUseStationListAPI {
  getStationList: (payload: IBounds) => void
  stationList: IStationInfo[]
  status: ERequestStatus
}

export const useStationListAPI = (): IUseStationListAPI => {
  const [{ data: stationList, status }, actions] = useRestAPIReducer<IStationInfo[]>([])

  const getStationList = useCallback((payload: IBounds) => {
    stationAPI.getStationList({ payload }, actions)
  }, [])

  useEffect(() => {
    getStationList(ME_BOUNDS)
  }, [])

  return {
    getStationList,
    stationList,
    status,
  }
}
