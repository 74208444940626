function setStyle(element: HTMLElement, style: Partial<CSSStyleDeclaration>): void {
  for (const key in style) {
    element.style[key] = style[key]!
  }
}

function createCustomElement(tagName: string, style: Partial<CSSStyleDeclaration>): HTMLElement {
  const element = document.createElement(tagName)

  setStyle(element, style)

  return element
}

export { setStyle, createCustomElement }
