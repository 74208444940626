import { useEffect } from "react"

import { handbookAPI } from "@api"
import { useRestAPIReducer } from "../_useRestAPIReducer"
import { ERequestStatus, IGas, IHandbook } from "@types"

interface IUseHandbookAPI {
  handbook: IHandbook
  status: ERequestStatus
}

export const useHandbookAPI = (): IUseHandbookAPI => {
  const [{ data: gas, status }, actions] = useRestAPIReducer<IGas[]>([])

  useEffect(() => {
    handbookAPI.getGasHandbook({ actions })
  }, [])

  return {
    handbook: {
      gas,
    },
    status,
  }
}
