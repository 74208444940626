import { IFilterController, IGas, IStation, IStationData } from "@types"

type TGetLastStationDataItem = (
  stationId: IStation["id"],
  emission: number,
  cb: (data: IStationData[]) => void
) => Promise<void>

class MapDTO {
  private _filterController: IFilterController | null = null
  private _gas: IGas[] = []
  private _navToMarketplace: (() => void) | null = null
  private _getLastStationDataItem: TGetLastStationDataItem | null = null

  set mapData(mapData: {
    filterController: IFilterController
    navToMarketplace: () => void
    getLastStationDataItem: TGetLastStationDataItem
  }) {
    this._filterController = mapData.filterController
    this._navToMarketplace = mapData.navToMarketplace
    this._getLastStationDataItem = mapData.getLastStationDataItem
  }

  get filterController(): IFilterController | null {
    return this._filterController
  }

  get gas(): IGas[] {
    return this._gas
  }

  set gas(gas: IGas[]) {
    this._gas = gas
  }

  get navToMarketplace(): (() => void) | null {
    return this._navToMarketplace
  }

  get getLastStationDataItem(): TGetLastStationDataItem | null {
    return this._getLastStationDataItem
  }
}

export const mapDTO = new MapDTO()
