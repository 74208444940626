import axios from "axios"

import { API_URL } from "@constants"
import { CookieJar } from "../helpers"

const $AXIOS = axios.create({
  withCredentials: true,
  baseURL: API_URL,
})

$AXIOS.interceptors.request.use((config) => {
  return {
    ...config,
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Accept-Language": `${CookieJar.getCookie("language") || "RU"}`,
    },
  }
})

$AXIOS.interceptors.response.use(
  (config) => config,
  async (error) => {
    const originalRequest = error.config

    if (error.response.status === 401 && error.config && !error.config._isRetry) {
      originalRequest._isRetry = true

      try {
        await axios({
          method: "POST",
          url: `${API_URL}auth/refresh_cookie/`,
          withCredentials: true,
        })

        return $AXIOS.request(originalRequest)
      } catch ({ response: { status } }) {
        if (status === 403) {
          CookieJar.deleteCookie("isAuthorized")
        }
        console.log(error)
      }
      throw error
    }
    throw error
  }
)

export default $AXIOS
