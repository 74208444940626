import { IRestAPIActions } from "../../hooks"
import { IRequestCallbacks, IRequestWrapperArgs, AxiosPromised } from "./_types"

const cbPlaceholder = { onSuccess: null, onError: null, onPending: null }

function requestWrapper<Data, Request extends (reqParams: Parameters<Request>[0]) => AxiosPromised<Data>>(
  request: Request
): (args: IRequestWrapperArgs<Data>, actions?: IRestAPIActions<Data>) => Promise<void> {
  return async ({ params, payload, ...callbacks }, actions): Promise<void> => {
    const _request = async (): AxiosPromised<Data> => request({ params, payload })
    processRequest<Data>(_request, { ...callbacks }, actions)
  }
}

function noArgsRequestWrapper<Data, Request extends () => AxiosPromised<Data>>(
  request: Request
): ({ callbacks, actions }: { callbacks?: IRequestCallbacks<Data>; actions?: IRestAPIActions<Data> }) => Promise<void> {
  return async ({ callbacks, actions }): Promise<void> => {
    const _request = async (): AxiosPromised<Data> => request()
    processRequest<Data>(_request, callbacks, actions)
  }
}

async function processRequest<Data>(
  request: () => AxiosPromised<Data>,
  callbacks?: IRequestCallbacks<Data>,
  actions?: IRestAPIActions<Data>
): Promise<void> {
  const _cb = callbacks ?? cbPlaceholder
  const { onSuccess, onError, onPending } = _cb

  try {
    actions?.setPending(true)
    onPending && onPending(true)

    const { status, data } = await request()

    if (status >= 200 || status < 300) {
      try {
        actions?.setData(data)

        onSuccess && onSuccess(data)
        onPending && onPending(false)
      } catch (error) {
        console.error("Ошибка onSuccess: " + error)

        actions?.setPending(false)
        onPending && onPending(false)
      }
    }
  } catch (error) {
    console.error("Ошибка запроса: " + error)

    actions?.setError()
    onError && onError()
    onPending && onPending(false)
  }
}

export type { IRequestWrapperArgs }
export { requestWrapper, noArgsRequestWrapper }
