const WEEK_IN_MS = 604_800_000

export class TimePeriods {
  static now(): Date {
    return this.setSMsToZero(new Date())
  }

  static fiveMinutesAgo(): Date {
    return this.minutesAgo(5)
  }

  private static minutesAgo = (minuteCount = 1): Date => {
    const minutesInMs = minuteCount * 60 * 1000

    const minutesAgo = new Date(new Date().getTime() - minutesInMs)

    return this.setSMsToZero(minutesAgo)
  }

  static hourAgo(): Date {
    const hourInMs = 60 * 60 * 1000

    const hourAgo = new Date(new Date().getTime() - hourInMs)

    return this.setSMsToZero(hourAgo)
  }

  static dayAgo(): Date {
    const dayInMs = 24 * 60 * 60 * 1000

    const dayBefore = new Date(new Date().getTime() - dayInMs)

    return this.setSMsToZero(dayBefore)
  }

  static weekAgo(weeks = 1): Date {
    return this.shiftWeek(weeks)
  }

  static weekForward(): Date {
    return this.shiftWeek(1, "forward")
  }

  private static shiftWeek(weeks = 1, direction = "back"): Date {
    const weekInMs = weeks * WEEK_IN_MS

    const weekBefore = new Date(
      direction === "back" ? new Date().getTime() - weekInMs : new Date().getTime() + weekInMs
    )

    return this.setSMsToZero(weekBefore)
  }

  static monthAgo(monthCount = 1): Date {
    if (1 > monthCount && monthCount > 12) throw new Error("Количество месяцев должно быть в диапазоне от 1 до 12")

    const currentMonth = new Date().getMonth()
    const monthRemainder = currentMonth - monthCount

    if (monthRemainder <= 0) {
      const currentYear = new Date().getFullYear()
      const yearBefore = new Date(new Date().setFullYear(currentYear - 1))

      const updatedMonthDate = new Date(yearBefore.setMonth(12 + monthRemainder))

      return this.setSMsToZero(updatedMonthDate)
    }

    const monthsBefore = new Date(new Date().setMonth(monthRemainder))

    return this.setSMsToZero(monthsBefore)
  }

  private static setSMsToZero(date: Date): Date {
    const newDate = new Date(date.setSeconds(0))

    return new Date(newDate.setMilliseconds(0))
  }
}
