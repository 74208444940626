import React, { createContext, ReactNode, useContext, useEffect, useLayoutEffect, useRef, useState } from "react"

import { MiniStore } from "./_MiniStore"

interface ICreateOptimizedContext<T> {
  Provider: (args: { store: MiniStore<T>; children: ReactNode }) => JSX.Element
  useStateSelector: <Result>(selector: (state: T) => Result) => Result
  useUpdate: () => (partialNewState: Partial<T>) => void
}

export function createOptimizedContext<T>(): ICreateOptimizedContext<T> {
  const Context = createContext<MiniStore<T> | null>(null)

  const Provider: ICreateOptimizedContext<T>["Provider"] = ({ store, children }) => {
    return <Context.Provider value={store}>{children}</Context.Provider>
  }

  const useStore = (): MiniStore<T> => {
    const store = useContext(Context)
    if (!store) {
      throw new Error("Невозможно вызвать `useStore` вне компонента `Provider`")
    }
    return store
  }

  const useStateSelector: ICreateOptimizedContext<T>["useStateSelector"] = (selector) => {
    const store = useStore()
    const [value, setValue] = useState(() => selector(store.getState()))
    const selectorRef = useRef(selector)
    const stateRef = useRef(value)

    useLayoutEffect(() => {
      selectorRef.current = selector
      stateRef.current = value
    })

    useEffect(() => {
      return store.subscribe(() => {
        const state = selectorRef.current(store.getState())

        if (stateRef.current === state) return

        setValue(state)
      })
    }, [store])

    return value
  }

  const useUpdate: ICreateOptimizedContext<T>["useUpdate"] = () => {
    const store = useStore()

    return store.update
  }

  return { Provider, useStateSelector, useUpdate }
}
