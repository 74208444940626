import React, { FC, useMemo } from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom"

import { CookieJar } from "@utils"

export const PrivateRoute: FC = () => {
  const location = useLocation()

  const isAuth = useMemo(() => CookieJar.getCookie("isAuthorized") === "authorized", [document.cookie])

  if (!isAuth) {
    return <Navigate to="/" state={{ from: location }} replace />
  }

  return <Outlet />
}
