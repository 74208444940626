import { IStationInfo } from "./data"

enum EAll {
  ALL = "All",
}

enum EEmission {
  CO2e = "1",
  CO2 = "2",
  CO = "3",
  O3 = "4",
  CH4 = "5",
  N2O = "6",
  SF6 = "7",
  HFCs = "8",
  PFCs = "9",
}

enum ELayer {
  COMPLEX = "COMPLEX",
  AERIAL = "AERIAL",
  ABSORPTION = "ABSORPTION",
  STATIONS = "STATIONS",
  OBJECTS = "OBJECTS",
}

interface IMapFilterValues {
  layers: ELayer[]
  emission: EEmission
  selectedElement: IStationInfo | null
}

type TFilterCb = (filters: IMapFilterValues) => void
type THasPassed = <T extends EEmission | ELayer | EAll>(value: T) => boolean

interface IFilterController {
  get filters(): IMapFilterValues
  setFilters: TSetMapFilters
  hasPassedFilters: THasPassed
  onFiltersChange: (cb: TFilterCb) => void
}

type TSetMapFilters = (newFilters: Partial<IMapFilterValues>) => void

export { EAll, EEmission, ELayer }

export type { IMapFilterValues, TSetMapFilters, TFilterCb, THasPassed, IFilterController }
